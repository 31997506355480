import SEO from "../components/Head/Social"

import Navigation from '../components/Landing/Navigation'; 
import Footer from '../components/Landing/Footer';

const IndexPage = () => (
  <>
    <SEO title="Landing" />
    <Navigation />
    <div style={{margin: '260px 100px 200px 100px'}}>
      <h3>This link is broken or typed incorrectly. Please try again or refresh the page.</h3>
    </div>
    <Footer />
  </>
)

export default IndexPage
